import Image1 from "../../img/myfit.png";
import Image1_1 from "../../img/myfit1.png";
import Image1_2 from "../../img/myfit2.png";
import Image1_3 from "../../img/myfit3.png";

import Image2 from "../../img/netflix1.png";

import Image3 from "../../img/travel.png";
import Image3_1 from "../../img/travel2.png";
import Image3_2 from "../../img/travel3.png";



import Image6 from "../../img/gamestore.jpg";
import Image6_1 from "../../img/gamestore1.png";
import Image6_2 from "../../img/gamestore2.png";



import Image4 from "../../img/logtrans.png";
import Image4_1 from "../../img/logtrans2.png";
import Image4_2 from "../../img/Logtrans3.png";


import Image5 from "../../img/wordpress.png";
import Image5_1 from "../../img/souffleur.jpg";
import Image5_2 from "../../img/souffleurdeverre.png";
import Image5_3 from "../../img/Bernard.png";


import Image8 from "../../img/ps5.png";
import Image8_2 from "../../img/ps5_2.png";
import Image8_3 from "../../img/ps5_3.png";


import Image9 from "../../img/space.png";
import Image9_2 from "../../img/Space2.png";
import Image9_1 from "../../img/Space3.png";


import Image10 from "../../img/rick.png";
import Image10_2 from "../../img/rick2.png";
import Image10_1 from "../../img/rick3.png";
import Image10_3 from "../../img/rick4.png";

import Image11 from "../../img/neptune.png";
import Image11_1 from "../../img/neptune2.png";
import Image11_2 from "../../img/neptune3.png";
import Image11_3 from "../../img/neptune4.png";


import Image12 from "../../img/Zelda.png"
import Image12_2 from "../../img/Zelda (1).png"
import Image12_3 from "../../img/Zelda (2).png"
import Image12_4 from "../../img/Zelda (3).png"

const PortfolioData = [
  {
    id: 3,
    type: "Site web de suivi sportif",
    image: Image1,
    image2: Image1_1,
    image3: Image1_2,
    image4: Image1_3,
    tag: ["php"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Site web de suivi sportif",
        client: "Projet de validation pour le Titre développeur web/web mobile",
        language: "Php, CSS, Javascript, SQL",
        preview: "www.brice76.fr",
        link: "https://www.brice76.fr/",
      },
    ],
  },
  {
    id: 4,
    type: "Copie project",
    image: Image2,
    image2: Image2,
    image3: Image2,
    image4: Image2,
    tag: ["php" , "javascript"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Copie statique du site Netflix",
        client: "Perso",
        language: " Php Css Javascript",
        preview: "demo.brice76.fr",
        link: "https://demo.brice76.fr",
      },
    ],
  },
  {
    id: 5,
    type: "Site web React_JS",
    image: Image3,
    image2: Image3_1,
    image3: Image3_2,
    image4: Image3,
    tag: ['react' , 'sass'],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Site web construt avec React-js",
        client: "Perso",
        language: " HTML, CSS, Javascript",
        preview: "www.travel.brice76.fr",
        link: "https://travel.brice76.fr",
      },
    ],
  },
  {
    id: 6,
    type: "E-commerce",
    image: Image6_1,
    image2: Image6_2,
    image3: Image6,
    image4: Image6_1,
    tag: ['javascript'],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Site web e-commerce",
        client: "Perso",
        language: " HTML, CSS, Javascript",
        preview: "Gamestore",
        link: "https://gamestore.brice76.fr",
      },
    ],
  },
  {
    id: 7,
    type: "Siteweb WordPress",
    image: Image5,
    image2: Image5_2,
    image3: Image5_3,
    image4: Image5_1,
    tag: ['wordpress'],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Site web d'un artisan",
        client: "Perso",
        language: "WordPress builder Elementor",
        preview: "Souflleur de verre",
        link: "https://wordpress.brice76.fr",
      },
    ],
  },
  {
    id: 9,
    type: "Site Web",
    image: Image4,
    image2: Image4_1,
    image3: Image4_2,
    image4: Image4,
    tag: ["wordpress"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Site Web sous WordPress avec un thème sur-mesure",
        client: "Réel",
        language: "Wordpress, Php, JavaScript, SCSS, ACF",
        preview: "logtrans-services.fr/",
        link: "https://logtrans-services.fr/",
      },
    ],
  },
  {
    id: 10,
    type: "Site Web",
    image: Image9,
    image2: Image9_1,
    image3: Image9_2,
    image4: Image9,
    tag: ["javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Site Web inspiré par l'entreprise space X",
        client: "Perso",
        language: "Html, CSS, JavaScript",
        preview: "space.brice76.fr",
        link: "https://space.brice76.fr/",
      },
    ],
  },
  {
    id: 11,
    type: "Jeux Web",
    image: Image8,
    image2: Image8_2,
    image3: Image8_3,
    image4: Image8,
    tag: ["javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Copie menu ps5",
        client: "Perso",
        language: "JavaScript",
        preview: "ps5.brice76.fr",
        link: "https://ps5.brice76.fr/",
      },
    ],
  },
  {
    id: 12,
    type: "Jeux Web",
    image: Image10,
    image2: Image10_1,
    image3: Image10_2,
    image4: Image10_3,
    tag: ["javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Jeux chasse taupe sur le thème Rick et Morty",
        client: "Perso",
        language: "JavaScript, CSS",
        preview: "rick.brice76.fr",
        link: "https://rick.brice76.fr/",
      },
    ],
  },
  {
    id: 13,
    type: "Front Web",
    image: Image11,
    image2: Image11_1,
    image3: Image11_2,
    image4: Image11_3,
    tag: ["php", "javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Front du site web",
        client: "Partenariat avec un développeur back-end pour effectuer le front du site",
        language: "Laravel, JavaScript",
        preview: "neptune-encheres",
        link: "https://www.neptune-encheres.com",
      },
    ],
  },
  {
    id: 14,
    type: "Jeux Web",
    image: Image12,
    image2: Image12_2,
    image3: Image12_3,
    image4: Image12_4,
    tag: ["javascript"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Jeu rétro inspiré par Zelda",
        client: "Perso",
        language: "JavaScript, CSS",
        preview: "zelda.brice76.fr",
        link: "https://zelda.brice76.fr/",
      },
    ],
  },
];

export default PortfolioData;
