import React from "react";
import 'react-tabs/style/react-tabs.css';
import particlesConfig from "../../config/particle-config";
import particlesBlackConfig from "../../config/pr-s-black";
import Particles from "react-tsparticles";
import heroImg from "../../img/hero/icon.svg";
import heroImgMobile from "../../img/hero/icon.svg";
import cv from "../intro/cv_brice.pdf";
import cv2 from "../intro/cvBriere_Brice_.pdf";


// function btnContact() {
//   var btnContact = document.getElementById('btnContact');
//   var tabHome = document.getElementById("tab:r1:1")
//   if (
//     tabHome.classList.contains('react-tabs__tab--selected')){
//     tabHome.classList.remove('react-tabs__tab--selected');
//   }
//   var tab = document.getElementById('tab:r1:4').classList.add('react-tabs__tab--selected');
// }




const heroContent = {
    heroImage: heroImg,
    heroMobileImage: heroImgMobile,
    heroTitleName: "Bonjour, Je m'appelle Brice.",
    heroDesignation: "Je suis Développeur Web",
    heroDescriptions: `Je m'appelle Brice Briere et je suis développeur je vous assiste dans la création d’un site performant et à votre image.
    Je peux vous accompagner sur différents types de projets. Du design de sites web à la création de sites internet en passant par l'optimisation SEO, je pourrais vous aider sur l’ensemble de vos projets digitaux.
    Je suis disponible pour vous aider dans vos projets.
    Si vous avez une question n'hesitez pas à me contacter. `,
    heroBtn: "Télécharger mon cv",
  };

const Intro4 = ({ sliderRef, blackStar }) => {
    return (
        <header ref={sliderRef} className="particles circle-bg valign">


<div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg d-none d-lg-block" data-aos="fade-down-right" data-aos-duration="1300">
          <img
              src={heroContent.heroMobileImage}
              className="img-fluid" alt="hero man"/>
        </div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start" data-aos="fade-right" data-aos-duration="1500">
          <div>
            <img
              src={heroContent.heroMobileImage}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              {heroContent.heroTitleName}.
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
            {/* <button className="button" id="btnContact" onClick={btnContact}> */}
            <button className="button">
              <a href={cv2} target="_blank">
              <span className="button-text">{heroContent.heroBtn}</span>
              <span className="button-icon fa fa-arrow-right"></span>
              </a>
            </button>
          </div>
        </div>
      </div>

                {/* <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="cont text-center text-white">
                                <h1>
                                    <span className="color-font">Bienvenue</span> sur le<br />
                                    <span className="color-font"> site2 de </span> Brice.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div> */}

            <Particles
                id="particles-js"
                options={blackStar ? particlesBlackConfig : particlesConfig}
            />

            <div className="gradient-circle"></div>
            <div className="gradient-circle two"></div>
            <div className="line bottom left"></div>
        </header >
    );
};

export default Intro4;
