import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { FiHeart, FiClock, FiCheckCircle, FiAward } from "react-icons/fi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { TbBrandJavascript } from 'react-icons/tb';
import {FaReact, FaSass, FaPhp, FaLaravel} from 'react-icons/fa'
import {SiMysql, SiBulma, SiFilezilla} from 'react-icons/si'
import {DiCss3} from 'react-icons/di'
import {BsWordpress, BsFillBootstrapFill, BsGithub} from 'react-icons/bs'


class CounterOne extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        let Data = [
            {
                icon: <FiHeart />,
                countNum: 40,
                countTitle: 'Projets',
            },

            {
                icon: <FiClock />,
                countNum: 575,
                countTitle: 'Commits',
            },

            {
                icon: <FiCheckCircle />,
                countNum: 490,
                countTitle: 'Tasses de café',
            },

            {
                icon: <FiAward />,
                countNum: 5,
                countTitle: 'Clients satisfait',
            }
        ];


        let Tech = [
            {
                techIcon: <BsWordpress className="icon-tech"/>,
                techTitle: 'WordPress',
                duration: '1350',
            },
            {
                techIcon: <DiCss3 className="icon-tech"/>,
                techTitle: 'CSS',
                duration: '1550',
            },
            {
                techIcon: <SiMysql className="icon-tech"/>,
                techTitle: 'MySQL',
                duration: '1750',
            },
            {
                techIcon: <FaSass className="icon-tech"/>,
                techTitle: 'Sass',
                duration: '1950',
            },
            {
                techIcon: <TbBrandJavascript className="icon-tech"/>,
                techTitle: 'JavaScript',
                duration: '2350',
            },
            {
                techIcon: <FaReact className="icon-tech"/>,
                techTitle: 'React-Js',
                duration: '2650',
            },
            {
                techIcon: <BsFillBootstrapFill className="icon-tech"/>,
                techTitle: 'Bootstrap',
                duration: '2950',
            },
            {
                techIcon: <SiBulma className="icon-tech"/>,
                techTitle: 'Bulma',
                duration: '3050',
            },
            {
                techIcon: <FaPhp className="icon-tech"/>,
                techTitle: 'Php',
                duration: '3150',
            },
            {
                techIcon: <BsGithub className="icon-tech"/>,
                techTitle: 'Git',
                duration: '3250',
            },
            {
                techIcon: <SiFilezilla className="icon-tech"/>,
                techTitle: 'FileZilla',
                duration: '3350',
            },
            {
                techIcon: <FaLaravel className="icon-tech"/>,
                techTitle: 'Laravel',
                duration: '3150',
            },
        ];

        return (
            <Fragment>
                <div className="row">
                    {Data.map((value, index) => (
                        <div className="im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                            <div className="im_counterup">
                                <div className="inner">
                                    <div className="icon">
                                        {value.icon}
                                    </div>
                                    <h2 className="counter">
                                        <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                            <CountUp redraw={true} end={this.state.didViewCountUp ? value.countNum : 0} />
                                        </VisibilitySensor>

                                    </h2>
                                    <p className="description">{value.countTitle}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row mt-5 pt-5 text-center">
                    <p className="text-light"> Les Technologie utiliser !</p>

                <div className="d-flex justify-content-center">
                    <div className="icon_exp">
                    {Tech.map((value, index) => (
                        <div className="drop" data-aos="flip-right" data-aos-duration={value.duration}>
                            <div className="image_icon" id="">
                            {value.techIcon}
                                <a href="#" className="icon_text"></a>
                                <div className="text_drop">
                                <p>{value.techTitle}</p>
                                    <div className="background"></div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;