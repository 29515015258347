import React from "react";
// import 'font-awesome/css/font-awesome.min.css';
import { GrLinkedinOption } from 'react-icons/gr';


const SocialShare = [
  // {
  //   iconName: "fa fa-facebook",
  //   link: "https://www.facebook.com/",
  // },
  { iconName: "fa fa-linkedin", link: "https://www.linkedin.com/in/brice-briere-42037019a/" },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {/* <i className={val.iconName}></i> */}
            <GrLinkedinOption/>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
