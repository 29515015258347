import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor , FiMail, FiCopy } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Maintenance',
        description: 'Je vous propose la maintenance et les mises à jour de vos sites web.',
        duration: '1050',
    },
    {
        icon: <FiLayers />,
        title: 'Développement de site Web',
        description: "Je développe vos sites et applications web en optimisant les ressources et en m'assurant de la bone qualité du code",
        duration: '1300',
    },
    {
        icon: <FiUsers />,
        title: 'Formation',
        description: "Vous voulez d'acquerir des compétences techniques dans le domaine du développement web? Je vous apprends à utiliser les technologies tels que ( HTML, CSS, Javascript, WordPress)",
        duration: '1550',
    },
    {
        icon: <FiMonitor />,
        title: 'Développement Mobile',
        description: 'Je propose mes services pour le développement d’applications Android. Ensemble, nous définissons vos besoins, vos contraintes, vos attentes, ect....',
        duration: '1700',
    },
    {
        icon: <FiMail />,
        title: 'Référencement SEO',
        description: 'Devenez visible en améliorant votre positionnement sur Google auprès des internautes qui vous recherche sur internet.',
        duration: '1900',
    },
    {
        icon: <FiCopy />,
        title: 'Refonte site web',
        description: 'Refonte site web Besoin de moderniser un site ou de refaire une peinture au gout du jour?',
        duration: '2200',
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'services.',
        description = 'je mets à votre service mes compétences et mon expérience dans le web pour vous guider dans votre communication internet. <br /> Mon métier est de vous offrir un accompagnement de qualité durant toute votre communication sur internet : création de site web, référencement naturel, conseil webmarketing, positionnement sur les réseaux sociaux, hébergement & maintenance et accompagnement technique.',
        subtitle= '';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center text-light" data-aos="fade-up" data-aos-duration="1200">
                                
                            <div className="title-section text-left text-sm-center"data-aos="fade-up"data-aos-duration="1200">
                                <h1>
                                    Nos <span className="title">{title}</span>
                                </h1>
                                <span className="title-bg">About</span>
                            </div>
                            

                                {/* <span className="subtitle">{subtitle}</span> */}
                                {/* <h2 className="title">{title}</h2> */}
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i} data-aos="flip-right" data-aos-duration={val.duration}>
                                        {/* <a href="/service-details"> */}
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        {/* </a> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
