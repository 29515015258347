import './App.css';
import CounterOne from './component/counter/CounterOne';
import ServiceTwo from './component/service/ServiceTwo';
import Intro4 from './component/intro/Intro4';
import Portfolios from './component/portfolios/Portfolios';
import Portfolio from './component/portfolio/Portfolio';
import PortfolioModal from './component/portfolio/modal/Modal';
import Contact from "./component/Contact";
import Social from "./component/Social";
import Address from "./component/Address";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactDOM from 'react-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'font-awesome/css/font-awesome.min.css';

const menuItem = [
    //{ icon: "fa-home", menuName: "Intro" },
    { icon: "fa-home", menuName: "Accueil" },
    { icon: "fa-user", menuName: "Compétences" },
    { icon: "fa fa-cubes", menuName: "Services" },
    { icon: "fa-briefcase", menuName: "Portfolio" },
    { icon: "fa-envelope-open", menuName: "Contact" },
  ];


function App() {

        // this for animation
        useEffect(() => {
            AOS.init({
              duration: 1200,
            });
          }, []);

  return (
    <>
            {/* <Navbar/> */}
            <Tabs>
        <div className="header">
          <TabList className="icon-menu  revealator-slideup revealator-once revealator-delay1">
            {menuItem.map((item, i) => (
              <Tab className="icon-box" key={i}>
                <i className={`fa ${item.icon}`}></i>
                <h2>{item.menuName}</h2>
              </Tab>
            ))}
          </TabList>
        </div>
            <section className='firstSection'>
                <TabPanel className="home">
                    <div data-aos="fade-up" data-aos-duration="1200">
                    <Intro4 />
                    </div>
                </TabPanel>
            </section>

                {/* Start Service Area  */}
                <TabPanel className="about">
                    <section className='sdSection'> 
                        <div className="service-area">
                            <div className="container"
                        data-aos="fade-up"data-aos-duration="1200">
                                <ServiceTwo />
                            </div>
                        </div>
                    </section>
                </TabPanel>
                    {/* End Service Area  */}

                    {/* Start CounterUp Area */}
                    <TabPanel className="Number">
                    <section className='threeSection pt-5 pb-5'>
                        <div className="rn-counterup-area">
                            <div className="container"
                        data-aos="fade-up" data-aos-duration="1200">
                                <div className="row">
                                    <div className="col-lg-12">

                                    <div className="title-section text-left text-sm-center" data-aos="fade-up"data-aos-duration="1200">
                                        <h1>
                                            En quelques <span>chiffres</span>
                                        </h1>
                                        <span className="title-bg">Services</span>
                                    </div>

                                        <div className="section-title text-center text-light" data-aos="fade-up" data-aos-duration="1200">
                                            {/* <span className="subtitle">Les chiffres</span> */}
                                            <h2 className="title" id='title-count'>Ci-dessous, vous trouverez une liste des chiffres me concernant et des technologies sur lesquels je me suis concentré ces derniers mois/années.</h2>
                                            {/* <p className="description">Lorem.</p> */}
                                        </div>
                                    </div>
                                </div>
                                <CounterOne />
                            </div>
                        </div>
                    </section>
                </TabPanel>
                {/* End CounterUp Area */}

                {/* Start Portfolio Area */}
                {/* <section className='FourSection'>
                    <div className="portfolio-area ptb--120 bg_color--1">
                        <div className="portfolio-sacousel-inner mb--55">
                            <Portfolios />
                        </div>
                    </div>
                </section> */}
                {/* End Portfolio Area */}
                

                {/* Portfolio Content Starts */}
            <TabPanel className="portfolio professional">
                <section className='fourSection'>
                    <div className="title-section text-left text-sm-center"
                        data-aos="fade-up"
                        data-aos-duration="1200">
                        <h1>
                            Mon <span>portfolio</span>
                        </h1>
                        <span className="title-bg">Projets</span>
                    </div>
                        {/* End title */}
                        <Portfolio />
                </section>
            </TabPanel>
                {/* Portfolio Content Ends */}  



        {/* Contact Content Starts */}
      
        <TabPanel className="contact">
            <div className="title-section text-left text-sm-center"
            data-aos="fade-up" data-aos-duration="1200">
                <h1>
                    écrivez-<span>moi</span>
                </h1>
                <span className="title-bg">contact</span>
            </div>
            <div className="container" data-aos="fade-up"
            data-aos-duration="1200">
                <div className="row">
                {/*  Left Side Starts */}
                <div className="col-12 col-lg-4 text-light">
                    <h3 className="text-uppercase custom-title mb-0 ft-wt-600 pb-3">
                    Ne soyez pas timide !
                    </h3>
                    <p className="open-sans-font mb-4">
                    N'hésitez pas à me contacter. 
                    Je suis toujours ouvert à discuter pour de nouveaux projets, 
                    d'idées créatives ou encore d'opportunités.
                    </p>
                <Address />
                  {/* End Address */}

                <Social />
                  {/* End Social */}
                </div>
                {/* Left Side Ends */}

                {/*  Contact Form Starts  */}
                <div className="col-12 col-lg-8">
                    <Contact />
                </div>
                {/*  Contact Form Ends */}
                </div>
            </div>
            {/* End .container */}
            </TabPanel>
          {/* Contact Content Ends */}
    </Tabs>
    </>
  );
}
export default App;
