import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
  const form = useRef();
  const recaptchaRef = React.createRef();
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_6z4b0qh",
        "template_3j6nk5p",
        form.current,
        //"user_vYmDSd9PwIuRXUQEDjYwN"
        "XLpORVzybVIC1qpDJ"
      )
      .then(
        (result) => {
          console.log('ok');
          toast.success("Message envoyer!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("myForm").reset();
        },
        (error) => {
          console.log('fail');
          console.log(error);
          toast.error("Message non envoyer !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };

  return (
    <>
          <ToastContainer theme="colored" />
                
      <form id="myForm" className="contactform" ref={form} onSubmit={sendEmail}>

        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input type="text" name="name" placeholder="Votre Nom" required />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                type="email"
                name="user_email"
                placeholder="Votre Email"
                required
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                type="text"
                name="subject"
                placeholder="Votre objet"
                required
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                name="message"
                placeholder="Votre message"
                required
              ></textarea>
            </div>
          </div>
          {/* End .col */}
          <div className="pb-4">
            <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LcHHkMjAAAAAF_zrdRQu9TQ1tuFCZIkrITL5tdr"
            onChange={onChange}
            />
          </div>

          <div className="col-12">
            <button type="submit" className="button">
              <span className="button-text">Envoyer</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default Contact;
