import React, { Component } from "react";
import { BrowserRouter as Link } from 'react-router-dom';
//import logo from '../../img/';
//const logooo = require('./asset/img/logtrans.png');
const get_image = <img src={require("../../img/logtrans.png")} alt="React" className="card-img-top" />;
const get_image2 = <img src={require("../../img/myfit1.png")} alt="React" className="card-img-top" />;
const get_image3 = <img src={require("../../img/netflix.jpg")} alt="React" className="card-img-top" />;
const Portfolio_image = "url(" + ('/static/media/logtrans.77d7ab1dc83ad5888b68.png') + ")";
const Portfolio_image2 = "url(" + ('/static/media/myfit1.5dab21878233a65ffa5e.png') + ")";
const Portfolio_image3 = "url(" + ('/static/media/netflix.850029a5ccc8a1f93f53.jpg') + ")";



const PortfolioList = [
    {
        image: get_image,
        category: 'Lorem',
        title: 'Lorem',
        description: 'Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.',
        background: {
            background: Portfolio_image,
        }
    },
    {
        image: get_image2,
        category: 'Lorem',
        title: 'Lorem',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        background: {
            background: Portfolio_image2,
        }
    },
    {
        image: get_image3,
        category: 'Lorem',
        title: 'Lorem',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        background: {
            background: Portfolio_image3,
        }
    }

]
{
    PortfolioList.map((value, index) => (
        console.log(value.image)
    ))
};

class Portfolio extends Component {
    render() {
        let title = "Voici quelque'un de mes projets",
            description = 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet <br /> Lorem ipsum dolor sit amet.';
        return (
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center text-light">
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row p-5">
                            {PortfolioList.map((value, index) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-5" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                           {/* <div className="thumbnail" style={value.background}>
                                                <Link to="#" />
                                                 {value.image}
                                            </div> */}
                                        </div>
                                        {/* <Link className="transparent_link" to="/portfolio-details"/> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-2 g-4 pb-5">
                            {PortfolioList.map((value, index) => (
                                <div className="col im_portfolio">
                                    <div className="card thumbnail_inner">
                                        {value.image}
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="portfolio_heading">
                                                <div className="category_list">
                                                    <Link to="#">
                                                        {value.category}
                                                    </Link>
                                                </div>
                                                <h4 className="title">
                                                    <Link to="#">
                                                        {value.title}
                                                    </Link>
                                                </h4>
                                            </div>
                                            <div className="portfolio_hover">
                                                <p>{value.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;